import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageTitleBannerStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props =>
        props.title === 'Fleet Insider By One9' ? '100%' : 'calc(100% - 40px)'};
    margin: 28px auto;
    padding: 50px 38px;
    background-color: ${props => props.theme.colors.primaryYellow};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: ${props => props.theme.fonts[1]}, sans-serif;
    font-size: 2.125rem;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;

    @media screen and (min-width: 1024px) {
        width: 100%;
        margin-top: 0;
        padding: 72px 0;
        font-size: 2.75rem;
    }
`;

const PageTitleBanner = ({ title }) => (
    <PageTitleBannerStyled title={title}>{title}</PageTitleBannerStyled>
);

PageTitleBanner.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageTitleBanner;
