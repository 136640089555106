import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import scrollToElement from 'scroll-to-element';

import PrivacyStyled from '../components/page-styles/privacy-styles';

import Layout from '../components/Layout';
import PageTitleBanner from '../components/PageTitleBanner';

import SEO from '../components/SEO';

class Privacy extends Component {
    componentDidMount = () => {
        if (window.location.hash) {
            scrollToElement(window.location.hash, {
                offset: 600,
                duration: 1000,
            });
        }
    };

    buttonClicked = eventName => {
        window.dataLayer.push({
            event: eventName,
            pagePath: '/',
            pageTitle: 'Home',
        });
    };

    render() {
        const { size } = this.props;
        return (
            <Layout hideNav={true} needsSolidHeader={true} size={size}>
                <PrivacyStyled>
                    <SEO title="Privacy" />
                    <PageTitleBanner title="Terms of Use Agreement" />
                    <div className="privacy__content">
                        <p>
                            In consideration of Pilot Travel Centers LLC's (together with its subsidiaries and affiliates, 
                            including, but not limited to Pilot Corporation, Flying J Inc. and SFJ Inc., collectively referred 
                            to as "PTC") allowing you to use any software application, whether tablet, smartphone or other app 
                            (including its contents and services) (the “App”), online or mobile website, Wi-Fi-access, myRewards  
                            Loyalty Program, or Prime Parking Program ( including all of their respective content and services) 
                            (collectively be referred to “Electronic Platform”) and the services provided by and related to the
                            Electronic Platform, as applicable, you agree to be bound by the terms and conditions 
                            (“Terms of Use”) of this Agreement (the "Agreement").
                        </p>
                        <p>
                            You must read all of the Terms of Use as set forth herein prior to accessing or using any PTC Electronic 
                            Platform. You acknowledge and agree that you are 18 years of age or older, that use of the Electronic 
                            Platform constitutes acceptance of the Terms of Use, and that your continued use of the Electronic Platform 
                            constitutes a continuing acceptance of this Agreement as it may be revised from time to time in PTC's sole 
                            discretion and without notice to you. This Agreement will be accessible from the <a href="https://www.pilotflyingj.com/privacy-policy" 
                            target="_blank" rel="noreferrer noopener">www.pilotflyingj.com/terms-and-conditions</a>, 
                            and it is your responsibility to check this Agreement each time before you use the Electronic Platform for any revisions.
                        </p>

                        <h3 className="section__sub-title">
                            Use of the Electronic Platform
                        </h3>
                        <p>
                            The Electronic Platform is the property of PTC. You are granted a non-exclusive, revocable, and non-transferable 
                            limited license to view and use the Electronic Platform provided (a) it is used only for informational, personal, 
                            non-commercial purposes, and (b) you do not remove or obscure any copyright notice or other notices found within 
                            the Electronic Platform. You do not have the right to sublicense your rights under this Agreement. Without the 
                            express written consent of PTC, you are not allowed (i) to modify, copy, distribute, transmit, display, perform, 
                            reproduce, publish, license, create derivative works from, transfer or sell any information, products or services 
                            obtained directly from the Electronic Platform; (ii) to reproduce any part of the Electronic Platform or mirror 
                            any material contained within the Electronic Platform on any other server or application; or (iii) to link to or 
                            from the Electronic Platform.
                        </p>
                        <p>
                            PTC reserves the right, at its sole discretion, to deny access to the Electronic Platform or to take any other 
                            appropriate action if it believes that your conduct is in violation of applicable law or this Agreement, or for any other reason.
                        </p>

                        <h3 className="section__sub-title">Access</h3>
                        <p>
                            In order to access some of the features in the Electronic Platform, you may have to create an account.  
                            You many never use another’s account without permission.  When creating your account, you must provide 
                            accurate and complete information.  You are solely responsible for the activity that occurs with respect 
                            to any account you create and you must keep any associated password secure.  If you suspect a breach of 
                            security or unauthorized access to any account, you must notify PTC.  As set forth herein, although PTC 
                            will not be liable for any loss caused by unauthorized access, you be liable for the losses of PTC or 
                            others for such unauthorized use.
                        </p>

                        <h3 className="section__sub-title">
                            Computational Resources
                        </h3>
                        <p>
                            As consideration for your right to access and use the Electronic Platform, you agree that PTC has 
                            the right to utilize the processor, bandwidth and storage hardware on any computer, smartphone, or 
                            other relevant device that you use to access or use the App for the purpose of facilitating the 
                            communication and transmission of content and other data or features to or from you and/or PTC 
                            and/or other users of the App. As additional consideration, you also agree to release PTC and 
                            indemnify PTC from any claims, actions, demands, costs, damages, liabilities and settlements 
                            related in any way to PTC's utilization of the processor, bandwidth and storage hardware on any 
                            computer, smartphone, or other relevant device that you use to access and use the App.
                        </p>
                        <p>
                            You agree not to use or launch any automated systems, including, but not limited to “robots,
                            ” “spiders,” offline readers” etc. that accesses the websites or Apps or any part of the 
                            Electronic Platform in a manner that sends more request messages to PTC servers in a given 
                            period than a human can reasonably produce in the same period using on-line web browsing.  
                            Notwithstanding the foregoing, PTC grants the operators of public search engines permission 
                            to use spiders to copy materials from its website(s) for the sole purpose of creating publicly 
                            searchable indices of the materials, but not caches or archives of such materials.
                        </p>

                        <h3 className="section__sub-title">
                            Product and Service Representation
                        </h3>
                        <p>
                            The Electronic Platform may contain general information related to PTC travel center locations, 
                            daily fuel prices and amenities/accommodations (restaurants, number of parking spaces, number 
                            of diesel lanes, number of RV lanes, diesel exhaust fluid (DEF) availability and number of DEF 
                            lanes) available at each PTC travel center location, coupons and special offers, information 
                            related to points earned by participants in any of PTC's loyalty programs, links to and information 
                            from the PTC website and social media profiles, and an interactive customer service forum that will 
                            allow PTC customers to evaluate PTC travel centers that they visit. PTC makes no warranties, 
                            representations or guarantees that such information is accurate or that any service or product 
                            referenced in the material found in the Electronic Platform will be available at a specific time 
                            or at a certain store location. Availability of any offered fuel discount(s) to holders of the App 
                            is subject to federal, state and local law.  Such discount is void where prohibited by law. 
                        </p>

                        <h3 className="section__sub-title">
                            Links to Other Websites or Applications
                        </h3>
                        <p>
                            PTC may provide links to third party websites or other applications on the Electronic Platform from 
                            time to time. These links are provided as a convenience to you and other users of the Electronic 
                            Platform and the inclusion of any such link does not constitute an endorsement by PTC of the use 
                            of or content on such other websites or applications. You acknowledge and agree that PTC is not liable 
                            for any loss or damage which may be incurred by you for the (i) content of, (ii) technology implemented 
                            by, or (iii) privacy practices of third party websites and/or applications and all use is at your own risk. 
                            PTC makes no warranty or representation and disclaims any liability related to any linked websites or 
                            applications and/or their content, and PTC is not responsible for the terms of use or privacy practices 
                            of such other websites or applications. You should review the privacy and terms of use for each third party 
                            website or application and confirm they are acceptable prior to registration on or use of the third party 
                            website or application.
                        </p>

                        <h3 className="section__sub-title">
                            Prohibited Use of the Electronic Platform
                        </h3>
                        <p>
                            As an express condition of your use of the Electronic Platform, you explicitly agree not to use this Electronic 
                            Platform for any purpose that is unlawful or prohibited by this Agreement. Also, you agree not to use the Electronic 
                            Platform in any way that could damage, disable, overburden, or impair the Electronic Platform, or interfere with others' 
                            use of it. Furthermore, you agree not to attempt to gain access to PTC's computer systems or networks connected to PTC through 
                            fraud, hacking, password mining or any other means, or to attempt to reverse engineer any portion of the Electronic Platform.  
                            Finally, you agree not to use the Electronic Platform to attempt to gain a competitive advantage over PTC.
                        </p>

                        <h3 className="section__sub-title">Trademarks</h3>
                        <p>
                            All trademarks, service marks, trade names and trade dress on the Electronic Platform are owned or licensed by PTC., 
                            and you may not use any such trademarks, service marks, trade names or trade dress without the express written permission of PTC.
                        </p>
                        <p>
                            PTC may use trademarks, service marks, logos, graphics, designs, names, and the like from third parties for display on the 
                            Electronic Platform. Such posting is done with the permission of these third parties, and such material is to be considered 
                            the exclusive property of the respective third parties.
                        </p>

                        <h3 className="section__sub-title">Copyrights</h3>
                        <p>
                            All software and content included on the Electronic Platform, including all text, graphics, logos and images, 
                            is owned by PTC or third parties and is protected by United States and international copyright laws. You may 
                            view and use the content of the Electronic Platform in connection with your use of the Electronic Platform. 
                            Any other use, including the reproduction, copying, distribution, transmission, republication, display or 
                            creation of works derivative of the content of the Electronic Platform is strictly prohibited, except as 
                            otherwise specifically set forth herein. You do not acquire ownership rights to any content or other materials 
                            viewed on or through the Electronic Platform. The posting of information or materials on the Electronic Platform 
                            does not constitute a waiver of any rights therein. You may not (and you may not permit anyone else to) copy, 
                            modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract any source code 
                            of the Electronic Platform or any part thereof, unless this is expressly permitted or required by law, or unless 
                            you have been granted written permission to do so by an authorized representative of PTC.
                        </p>

                        <h3 className="section__sub-title">Claims of Intellectual Property Violations and Agent for Notice</h3>
                        <p>
                            If you believe that your work has been copied in a way that constitutes copyright infringement, or that your 
                            intellectual property rights have been otherwise violated, please provide PTC with the following information:
                            <br /> <br />
                            <h6> 1. &emsp;An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright 
                                or other intellectual property interest;</h6> <br />
                            <h6> 2. &emsp;A description of the copyrighted work or other intellectual property that you claim has been infringed;</h6> <br />
                            <h6> 3. &emsp;Your address, telephone number, and email address;</h6> <br />
                            <h6> 4. &emsp;A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright 
                                or intellectual property owner, its agent, or the law;</h6> <br />
                            <h6>5. &emsp;A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and 
                                that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</h6> <br />
                                <h6>Send to: Pilot Flying J, 5508 Lonas Drive, Knoxville, TN 37909</h6>
                        </p>
                        

                        <h3 className="section__sub-title">Disclaimer</h3>
                        <p>
                            THE ELECTRONIC PLATFORM IS PROVIDED BY PTC ON AN "AS IS" "WHERE IS" AND "AS AVAILABLE" BASIS. USERS OF THE ELECTRONIC 
                            PLATFORM ASSUME ALL RESPONSIBILITY AND RISK FOR THE USE OF THE ELECTRONIC PLATFORM. PTC MAKES NO REPRESENTATIONS OR 
                            WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE APP OR THE SECURITY, CONTENT, PRODUCTS OR 
                            SERVICES INCLUDED ON THE ELECTRONIC PLATFORM, THAT THE USE OF THE ELECTRONIC PLATFORM WILL MEET YOUR REQUIREMENTS/EXPECTATIONS 
                            OR THAT THE INFORMATION IS ACCURATE OR RELIABLE. TO THE FULLEST EXTENT PERMITTED BY LAW, PTC, ITS OFFICERS, DIRECTORS, 
                            EMPLOYEES, AGENTS, LICENSORS AND SUPPLIERS DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED 
                            TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR USE OR PURPOSE, AND NONINFRINGEMENT OF INTELLECTUAL 
                            PROPERTY OR OTHER PROPRIETARY RIGHTS. PTC MAKES NO WARRANTIES THAT THE USE OF THE ELECTRONIC PLATFORM OR SERVICES AVAILABLE 
                            ON THE ELECTRONIC PLATFORM WILL BE UNINTERRUPTED, AVAILABLE AT ALL TIMES AND LOCATIONS, SECURE, FREE FROM ERRORS, OR FREE 
                            FROM VIRUSES OR OTHER HARMFUL COMPONENTS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS 
                            ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
                        </p>
                        <p>
                            IN ADDITION, PTC DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED 
                            OR OFFERED BY A THIRD PARTY ON OR THROUGH THE ELECTRONIC PLATFORM OR ANY HYPERLINKED WEBSITE FOUND ON THE ELECTRONIC 
                            PLATFORM OR FEATURED IN ANY BANNER OR ADVERTISEMENT WITHIN THE ELECTRONIC PLATFORM. CONSEQUENTLY, PTC WILL NOT BE 
                            LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF SUCH PRODUCTS OR SERVICES.
                        </p>
                        <p>
                            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE ELECTRONIC PLATFORM IS DONE AT YOUR OWN DISCRETION 
                            AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT 
                            RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                        </p>
                        <p>
                            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM PTC OR THROUGH OR FROM THE ELECTRONIC PLATFORM 
                            SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                        </p>

                        <h3 className="section__sub-title">Indemnity</h3>
                        <p>
                            YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD PTC, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, AND SUPPLIERS, 
                            HARMLESS FROM AND AGAINST ANY CLAIMS, ACTIONS, DEMANDS, COSTS, DAMAGES, LIABILITIES AND SETTLEMENTS INCLUDING WITHOUT 
                            LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES, RESULTING FROM, OR ALLEGED TO RESULT FROM, YOUR VIOLATION OF THIS 
                            AGREEMENT OR PTC'S PRIVACY NOTICE, YOUR USE OF THE ELECTRONIC PLATFORM, OR YOUR VIOLATION OF THE RIGHTS OF ANY THIRD PARTY.
                        </p>

                        <h3 className="section__sub-title">
                            Limitation of Liability
                        </h3>
                        <p>
                            PTC WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND, WHETHER DIRECT, INDIRECT, PERSONAL INJURY, SPECIAL, PUNITIVE, INCIDENTAL, 
                            CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY KIND WHATSOEVER, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, ARISING FROM 
                            THE USE OF THE ELECTRONIC PLATFORM OR SERVICES AVAILABLE ON THE ELECTRONIC PLATFORM, THE UNAVAILABILITY OF THE ELECTRONIC 
                            PLATFORM, DAMAGE TO YOUR COMPUTER OR COMPUTER SYSTEM, OR ANY LOSS OF DATA OR BUSINESS INTERRUPTION RESULTING FROM SUCH USE, 
                            EVEN IF PTC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY 
                            FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL 
                            THE COLLECTIVE PTC’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING 
                            PERSONAL INJURY) EXCEED THE AMOUNT OF FIFTY DOLLARS ($50.00). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY 
                            FAILS OF ITS ESSENTIAL PURPOSE.
                        </p>
                        <p>
                            IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE ELECTRONIC PLATFORM OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY 
                            IS TO DISCONTINUE USING THE ELECTRONIC PLATFORM AND ANY SERVICES OR PROGRAMS PROVIDED THROUGH THE ELECTRONIC PLATFORM.
                            <u>
                                YOU AND PTC AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, 
                                AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS OR REPRESENTATIVE PROCEEDING.
                            </u>
                                &nbsp;EACH PARTY FURTHER WAIVES ALL RIGHTS TO HAVE DAMAGES MULTIPLED OR INCREASED.
                            <span id="privacy"></span>
                        </p>
                        <p>
                            THE NEGATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND PTC. 
                            THE ELECTRONIC PLATFORM WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS
                        </p>

                        <h3 className="section__sub-title">Privacy</h3>
                        <p>
                            We value your privacy.  To view and learn more about our Privacy Notice, click{' '}
                            <a
                                href="https://www.pilotflyingj.com/privacy-policy"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                here
                            </a>
                            .
                        </p>

                        <h3 className="section__sub-title">Jurisdiction</h3>
                        <p>
                            You expressly agree that exclusive jurisdiction of any dispute with PTC, or in any way relating to your use of the 
                            Electronic Platform, resides in the federal or state courts located in Knox County, State of Tennessee and you 
                            further agree and expressly consent to the exercise of personal jurisdiction in such courts in connection with 
                            any such dispute including any claim involving PTC or its affiliates, employees, contractors, officers, directors, 
                            telecommunication providers, and content providers. This Agreement shall be governed and construed by the laws of 
                            the State of Tennessee, without respect to its conflict of laws principles. BY YOUR USE OF THE ELECTRONIC PLATFORM, 
                            YOU HEREBY IRREVOCABLY WAIVE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY RIGHT YOU MAY HAVE TO A TRIAL 
                            BY JURY IN ANY LEGAL PROCEEDING DIRECTLY OR INDIRECTLY ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS 
                            CONTEMPLATED HEREBY (WHETHER BASED ON CONTRACT, TORT OR ANY OTHER THEORY).
                        </p>

                        <h3 className="section__sub-title">Assignment</h3>
                        <p>
                            You may not assign your use of the Electronic Platform. PTC may assign this Agreement or any part of it to any third party, 
                            without reservation, and in PTC's sole discretion.
                        </p>

                        <h3 className="section__sub-title">Miscellaneous</h3>
                        <p>
                            The Electronic Platform and these Terms of Use may be changed by PTC at any time and in PTC's sole discretion, 
                            with or without notice to you. Any modifications to this Agreement will be effective immediately upon posting 
                            any revised version of this Agreement on the Electronic Platform. It is your responsibility to review the Agreement 
                            each time you use the Electronic Platform so that you are aware of any modifications made. Usage of the Electronic 
                            Platform means you accept the then current form of the Terms of Use Agreement for the Electronic Platform. This 
                            Agreement constitutes the entire agreement between you and PTC, and supersedes all prior or contemporaneous agreements, 
                            representations, warranties or understandings with respect to the Electronic Platform, the content, products, or services 
                            provided by or through the Electronic Platform. If any provision of this Agreement is found to be invalid by any court having 
                            competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, 
                            which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver 
                            of such term or any other term. Should any provision of this Agreement be deemed invalid or unenforceable, such invalidity or 
                            enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of this Agreement.
                        </p>
                        <p>
                            By accessing or otherwise using this Electronic Platform you agree to be bound by the terms and conditions set forth herein.  
                            If you do not agree to these terms and conditions, you should stop using the Electronic Platform immediately.
                        </p>

                        <h3 className="section__sub-title">
                            <u>TERMS SPECIFIC TO THE MYREWARDS PLUS MOBILE APPLICATION (the “Mobile App”)</u>
                        </h3>
                        <p>
                            In addition to the Terms of Use, the following additional terms apply to your use of the Mobile App.  
                            Should any of these terms conflict, the specific terms below shall trump the general Terms of Use.
                        </p>

                        <h3 className='section__sub-title'>
                            Customer Service
                        </h3>
                        <p>
                            If you have any questions concerning the Mobile App, please call Guest Services at 1-877-866-7378.
                        </p>

                        <h3 className='section__sub-title'>
                            Access to the Mobile App
                        </h3>
                        <p>
                            Your use of the Mobile App may be inadvertently temporarily interrupted from time to time. 
                            PTC hereby reserves the right at any time and from time to time to temporarily or permanently 
                            modify or discontinue certain functions and/or features of the Mobile App, or the Mobile App 
                            in its entirety, without notice to you.
                        </p>

                        <h3 className='section__sub-title'>
                            SMS, MMS or Push Notification Messaging
                        </h3>
                        <p>
                            By electing to subscribe to SMS or MMS messaging in the Mobile App, you agree that PTC may 
                            communicate with you via SMS or MMS. Mobile opt-in information will NOT be shared with third parties. 
                            You may receive Mobile App push notifications via consent in your device settings. You agree to these SMS, 
                            MMS or Push Notification Terms, as well as PTC’s Terms of Use and <a href="https://pilotflyingj.com/privacy-notice" 
                            target="_blank" rel="noreferrer noopener">Privacy Notice</a>, which are incorporated 
                            herein by reference and can be viewed at the links provided here, or at <a href="https://pilotflyingj.com/" 
                            target="_blank" rel="noreferrer noopener">pilotflyingj.com</a>
                        </p>

                        <p>
                            By electing to subscribe to marketing messages on shortcode 26494, you expressly consent to receiving 
                            SMS messages using automated technology and enrollment in PTC's myRewards Loyalty Program (“myRewards”). 
                            Messages from shortcode 26494 may include myRewards Plus offers and rewards, sweepstakes information, 
                            and special promotions from PTC. By electing to subscribe to transactional messaging you consent to 
                            receive transactional, operational, or informational text messages at the phone number you provided 
                            at account creation. Messages from shortcode 40361 may include transactional notifications about your 
                            myRewards account and your Shower Reservation status. You can unsubscribe at any time from all messages 
                            on shortcode 26494 or 40361 by sending STOP to 26494 or STOP to 40361. Note that opting out is shortcode 
                            specific. After replying STOP, you may receive additional communications confirming that your request 
                            has been received and processed, and you may continue to receive text messages for a short period while 
                            PTC processes your request(s). If you opt out of one PTC text messaging program, you will remain opted 
                            into other PTC text messaging programs (if applicable) unless you opt out of those programs separately. 
                            After opting out of receiving text messages, you can re-enroll in any PTC text messaging program by 
                            following the instructions for that program.
                        </p>

                        <h3 className='section__sub-title'>
                            You can text HELP to shortcode 26494 or HELP to shortcode 40361 at any time for customer support 
                            information, opt-out information, and additional carrier costs.
                        </h3>

                        <p>
                            Consent to receive marketing text messages is not required as a condition of purchasing any goods 
                            or services. Texts may be sent using an automatic telephone dialing system. Please note we may not 
                            be able to deliver messages to all mobile carriers.
                        </p>        

                        <p>
                            Message and Data Rates May Apply. Message Frequency may vary. Your carrier's standard messaging 
                            rates apply to your entry or submission message, our confirmation, and all subsequent SMS correspondence. 
                            Please contact your wireless carrier for information about your messaging plan. Your carrier may impose 
                            message or charge limitations on your account that are outside our control. All charges are billed by 
                            and payable to your mobile service provider. You represent that you are 18 years or older and the owner 
                            or authorized user of the wireless device you use to subscribe for the service, and that you are authorized 
                            to approve the applicable charges. You agree that you will not initiate messages to the mobile phone of any 
                            other person or entity without authorization.
                        </p>       
                        <p>
                            For more information, call Guest Services at (877) 866-7378 or visit <a href="https://www.pilotflyingj.com/contact-us" 
                            target="_blank" rel="noreferrer noopener">https://www.pilotflyingj.com/contact-us</a>.
                        </p>
                        <p>
                            For marketing alerts text messages, you will receive no more than 10 messages/month. These messages will be 
                            recurring until you choose to quit receiving them by texting STOP to 26494.
                        </p>
                        <p>
                            For transactional alerts text messages, you will receive messages based on your specific transactional request. 
                            For example, if a professional driver requests a shower reservation:
                        <br /> <br />
                            Msg 1: You’re in line for a shower.<br />
                            Msg 2: Your shower is ready. Head to shower #4 and enter code XXXX to unlock the room. <br /> <br />
                            These messages will be recurring until you choose to quit receiving them by texting STOP to 40361.<br /> <br />
                            The service is available only in the United States.
                        </p>
                        <p>
                            Supported Carriers: Including, but not limited to, AT&T, T-Mobile®, Verizon Wireless, Sprint, Boost, Alltel, 
                            U.S. Cellular, Cellular One, MetroPCS, ACS/Alaska, Bluegrass Cellular, Cellular One of East Central Illinois, 
                            Centennial Wireless, Cox Communications, EKN/Appalachian Wireless, GCI, Illinois Valley Cellular, Immix/Keystone 
                            Wireless, Inland Cellular, Nex-Tech Wireless, Rural Cellular Corporation, Thumb Cellular, United Wireless, West 
                            Central (WCC), Cellcom, Cellsouth, Cricket, Cincinnati Bell and Virgin Mobile. <br /> <br />
                            T-Mobile is not liable for delayed or undelivered messages.
                        </p>
                        <p>
                            Data obtained from you in connection with this SMS service may include your cell phone number, your carrier's 
                            name, and the date, time and content of your messages and other information that you may provide. We may use 
                            this information to contact you and to provide the services you request from us, and to otherwise operate, 
                            develop and improve the service. Your wireless carrier and other service providers may also collect data about 
                            your SMS usage, and their practices are governed by their own policies. We will only use information you provide 
                            to the service to transmit your text message or as otherwise described in this document. Nonetheless, we reserve 
                            the right at all times to disclose any information as necessary to satisfy any law, regulation or governmental request, 
                            to avoid liability, or to protect our rights or property. When you complete forms online or otherwise provide us 
                            information in connection with the service, you agree to provide accurate, complete, and true information.
                        </p>
                        <p>
                            PTC may suspend or terminate your receipt of PTC text messages at any time. PTC reserves the right to modify 
                            or discontinue, temporarily or permanently, all or any part of PTC text message services, with or without notice.
                        </p>
                        <p>
                            The Privacy terms above apply. In accordance with our <a href="https://pilotflyingj.com/privacy-notice" 
                            target="_blank" rel="noreferrer noopener">Privacy Notice</a>, mobile information will not be shared 
                            with third parties without your consent.
                        </p>    
                        <p>
                            These SMS Terms may be updated by PTC at any time without prior notice. By continuing to be enrolled in any 
                            PTC promotional text messaging program, or by providing your cell phone number for transactional, operational, 
                            or informational text messages, you agree to any changes.
                        </p>    

                        <h3 className="section__sub-title">
                            <u>TERMS SPECIFIC TO PTC’S MYREWARDS LOYALTY PROGRAM (“myRewards”)</u>
                        </h3>
                        <p>
                            In addition to the Terms of Use, the following additional terms apply to your participation in the myRewards 
                            Program (“myRewards Terms”). Should any of these terms conflict, the specific terms below shall trump the 
                            general Terms of Use.
                        </p>
                        <p>
                            PTC reserves the right to modify or amend these myRewards Terms at any time by posting updated Terms at 
                            myrewards.pilotflyingj.com. and/or by amending these Terms of Use.  By using your myRewards Card, you 
                            agree to these terms and conditions, as amended. You are responsible for monitoring any changes or 
                            modifications to these myRewards Terms.
                        </p>
                        
                        <h3 className='section__sub-title'>
                            Program Overview
                        </h3>
                        <p>
                            PTC MyRewards Loyalty Program is a program where myRewards Loyalty Program Members (“Members”) can earn 
                            and obtain specific deals, discounts and sweepstakes based upon the type of card he or she possesses. 
                            In addition, PTC has created certain programs where myRewards points can be purchased through certain 
                            programs called “Purchased Points”.
                        </p>
                        <p>
                            myRewards Cards are available at participating PTC stores.  A list of participating travel centers and 
                            restaurants can be found at <a href="www.pilotflyingj.com/locations" 
                            target="_blank" rel="noreferrer noopener">www.pilotflyingj.com/locations</a> (collectively “PTC Locations”). All myRewards 
                            Cards must be activated and registered by (a) a PTC Location employee at the cash register, (b) by the 
                            customer at the PTC kiosk, (c) PTC’s Mobile App or (c) online at <a href="www.myrewards.pilotflyingj.com" 
                            target="_blank" rel="noreferrer noopener">www.myrewards.pilotflyingj.com</a> before 
                            Members can earn or obtain deals and discounts. Members must use a valid name, street address or P.O. Box, 
                            apartment number, city, state or province, ZIP or Postal Code, telephone number including area code, email 
                            and date of birth.  Providing fictitious, incorrect or false information may result in termination of the 
                            Member’s account and forfeiture of all existing benefits of the myRewards program.
                        </p>
                        <p>
                            Each household is allowed only one account (“Household Account”).  A Household Account is comprised of 
                            customers in the immediate family and can have more than one card but each card will be joined to the 
                            same account through the registration process, dependent on the customer’s phone number. Multiple cards 
                            under the same Household Account will be linked together and all cardholders will share the same rewards 
                            and account balances.  Each account has a maximum of 5 cards that can be activated and joined to the same 
                            Household Account at one time.  PTC will not be liable or responsible for any use of myRewards benefits in 
                            connection with a Household Account by the different cardholders. Members assume the risk of any and all 
                            Household Accounts and activation of additional cards under the Household Account.
                        </p>
                        <p>
                            By participating in the myRewards Program, the Member agrees to abide by these myRewards Terms and the general 
                            Terms of Use , as amended from time to time, with or without notice. The changes to the myRewards Terms may 
                            include but are not limited to changes in earning/using myRewards Points, participating locations, alterations 
                            or discontinuation of deals and discounts, continued availability of myRewards sweepstakes, requirements of 
                            participation, expiration dates, reduction in the value of myRewards Points or shower credits, <u>and/or</u> forfeiture 
                            of unused myRewards Points and benefits.  PTC reserves the right to change, limit, modify, decrease or cancel 
                            rewards and reward levels at any time with or without notice.
                        </p>
                        <p>
                            Each Member agrees to hold PTC harmless and waive any damages or claims against PTC which are associated with any 
                            change, limit, modifications, decrease or cancellation of rewards and reward levels. Each Member acknowledges and 
                            agrees that myRewards points have no cash value.
                        </p>
                        <p>
                            By registering in the myRewards Program, each Member hereby consents to PTC’s collection and use of each Member’s 
                            information in connection with the myRewards Program. <u>EACH MEMBER ALSO CONSENTS TO PTC’S ABILITY TO SHARE THE 
                            MEMBER’S INFORMATION IN ACCORDANCE WITH OUR PRIVACY NOTICE. If you do not wish to have your information shared, 
                            please visit <a href="https://privacy.pilotflyingj.com/s/" target="_blank" rel="noreferrer noopener">
                            https://privacy.pilotflyingj.com/s/</a> and follow the instructions</u>.
                        </p>
                        <p>
                            Each Member also consents and acknowledges that PTC, in its sole discretion, will resolve any and all 
                            questions/disputes regarding the myRewards Program, including but not limited to eligibility, benefits 
                            and rewards. <u>myRewards Points and awards are not redeemable for diesel fuel, diesel exhaust fluid, gasoline, 
                            beer, alcohol, tobacco products, e-cigarettes, gratuities, cash or cash equivalents, gift cards, Western 
                            Union transactions, Pegasus transactions, prepaid cards, lottery tickets, scale fees, or casino credits</u>. 
                            myRewards Points may not be transferred, purchased, sold, or bartered.
                        </p>

                        <h3 className='section__sub-title'>
                            Membership
                        </h3>
                        <p>
                            Any PTC customer, 18 years of age or older, may participate in the myRewards Program (“Member”). Team members of 
                            PTC and its affiliates are also eligible to participate in the myRewards Program.  However, team members and immediate 
                            family to or household members of team members may not qualify for sweepstakes contests.
                        </p>
                        <p>
                            PTC reserves the right to determine eligibility for membership and the right to terminate a Member’s membership at any 
                            time, with or without reason, with or without notice. The myRewards Program is a privilege and is not an entitlement 
                            program and there are no third party beneficiaries to the myRewards Program.
                        </p>

                        <h3 className='section__sub-title'>
                            Earning myRewards Points
                        </h3>
                        <p>
                            Members earn myRewards Points at participating PTC Locations.  In order to receive myRewards Points, a Member’s 
                            Professional Driver myRewards account must be accessed by presenting the Member’s card, using the Member’s 
                            phone number or the barcode on the mobile application at the time of purchase. <u>myRewards Points will not be 
                            issued for purchases made if the account is not accessed at the time of purchase</u>, and no myRewards Points will 
                            be given for purchases if the account is accessed after the purchase. Must present myRewards card, barcode in app, 
                            or phone number associated with myRewards account at time of purchase used in order to earn myRewards Points. 
                            myRewards Points are only earned for purchases/transactions that are made at PTC Locations and which are paid for 
                            in full. Points are awarded for purchases of diesel fuel, diesel exhaust fluid, and liquid natural gas. PTC reserves 
                            the right to reduce or eliminate myRewards Point balances, in its sole discretion, due to failure to follow these Terms 
                            and Conditions, misuse of the program, failure to pay for Member’s purchases in full, inactivity by the Member or for 
                            any other reason determined by PTC in its sole discretion. PTC further reserves the right to cancel and restrict future 
                            membership in the myRewards Program for any or no reason at all.
                        </p>

                        <h3 className='section__sub-title'>
                            PushForPoints&trade;
                        </h3>
                        <p>
                            To participate in the PushForPointsProgram, Members must activate the PushForPoints™ Program each month in the myRewards 
                            Plus app. After activation, Members earn 1 point per commercial diesel gallon and increase points with future qualifying 
                            fills. Must fuel at least 75 gallons for each “qualifying fill”; limit of 1 qualifying fill per day. Each qualifying fill 
                            adds additional 1/2 point per gallon to your myRewards account for all gallons purchased the rest of the month, up to 4 
                            points per gallon. Level points reset to 1 point per gallon on 1st of each month. Must present myRewards card, barcode in 
                            app, or phone number associated with myRewards account at time of purchase. Valid for diesel fuel purchases only; not valid 
                            on DEF. Valid only at participating U.S. Pilot or Flying J branded, owned and/or operated locations and One9 locations. Not 
                            valid in Canada. Void where prohibited or otherwise restricted. Purchase required. Invalid on prior purchases. Cannot be sold, 
                            bartered, or combined with other offers. No cash value, rain checks, or substitutions allowed. Offer may be modified or terminated 
                            at any time. Data rates may apply.
                        </p>
                        <p>
                            Any direct comparison in marketing and advertising materials of points earning and fills is based on an average fill size of 100 
                            diesel gallons in other nationally branded travel center rewards programs.
                        </p>

                        <h3 className='section__sub-title'>
                            Gas Discount
                        </h3>
                        <p>
                            For a limited time, members can receive a discount per gallon on the purchase of all variations of gasoline and auto 
                            diesel – discount may vary. Must present myRewards card, barcode in app, or phone number associated with myRewards account 
                            at time of purchase. Not valid on prior purchases or purchases other than stated above (DEF, propane and commercial diesel 
                            are excluded). No gallon restrictions; multiple use. Valid only at participating U.S. Pilot or Flying J branded, owned and/or 
                            operated locations and One9 locations. Not valid in Canada. Void where prohibited or otherwise restricted. Purchase required. 
                            Invalid on prior purchases. Cannot be sold, bartered, or combined with other offers. No cash value, rain checks, or substitutions 
                            allowed. Offer may be modified or terminated at any time. Data rates may apply.
                        </p>

                        <h3 className='section__sub-title'>
                            Special Offers, Special Points & Purchased Points
                        </h3>
                        <p>
                            Vendor partners or affiliates of PTC may periodically offer Members the opportunity to gain additional myRewards Points, special 
                            offers or incentives on the Member’s Household Account when making purchases of specified goods or services during specified 
                            promotional time periods at participating PTC Locations.  The vendor partner or affiliate will specify the special offer for 
                            qualifying purchases at the PTC Locations. The individual purchase requirements will be posted on the Kiosk or through other 
                            promotional materials.  In addition, PTC, its vendor partners or affiliates may periodically offer Members the opportunity to 
                            add, double or multiply the number of myRewards Points earned when making purchases of specified goods or services during specific 
                            promotional time periods.  You agree to hold PTC harmless from any promotions, special offers, or other opportunities offered by PTC’s 
                            vendors, partners or suppliers.
                        </p>
                        <p>
                            Some Members may receive the opportunity to earn additional myRewards points, or “Special Points”, through the Member’s affiliation 
                            with its employer, freight broker, and/or other channel partner.  Any additional points earned remain subject to the terms and conditions 
                            set forth herein.  Member understands and agrees that PTC has the right to reevaluate, modify, and/or terminate any Special Points provided 
                            to any employer, freight broker, channel partner, or other customer at any time.  No prior notice is required to be provided by PTC to Member 
                            regarding the modification or termination of any Special Points.
                        </p>
                        <p>
                            Finally, some Members may receive purchased points from their employers, vendors or suppliers (“Purchased Points”).  Purchased 
                            Points will not expire until redeemed at a PTC Location.  Any Purchased Point will be automatically redeemed first prior to any 
                            earned myRewards Points when redeeming your myRewards Points for purchase.  Certain escheat laws and regulations may apply to 
                            the Purchased Points.
                        </p>

                        <h3 className='section__sub-title'>
                            Redeeming MyRewards Points
                        </h3>
                        <p>
                            myRewards Points and other benefits can be redeemed by presenting the MyRewards Card, barcode in app, or phone number 
                            associated with myRewards at the Kiosk or the Cashier’s Desk. PTC is not responsible for any lost or stolen Cards or 
                            myRewards Points, regardless if earned or purchased. myRewards Points and other benefits cannot be credited back to 
                            an account if they are lost or stolen. Please actively review your account and immediately notify PTC of any 
                            discrepancies discovered.
                        </p>
                        <p>
                            You are responsible for any applicable taxes on the receipt of all applicable purchases made using myRewards 
                            Points or other benefits.  myRewards Points and other benefits are not redeemable for cash or cash equivalents, 
                            and are not transferable. Items purchased with myRewardsPoints or benefits may be returned for exchange only 
                            within thirty (30) days from date of purchase (i.e. no cash refunds). Items which are not returnable pursuant 
                            to PTC return policies are excluded from this thirty (30) day period and cannot be returned under any circumstances.
                        </p>
                        <p>
                            As allowable by law, rewards are subject to availability and subject to change, with or without notice and for 
                            any reason. PTC is not responsible, and assumes no liability, for changes or discontinuances of rewards or the 
                            availability of such rewards. There may be separate rules governing the awarding and redeeming of myRewards Points 
                            through other retailers or consumer product companies, if applicable. In addition, some states prohibit the 
                            award or redemption of myRewards Points. PTC and Member each agree to follow all applicable state, federal and local laws.
                        </p>
                        
                        <h3 className='section__sub-title'>
                            Military Discount
                        </h3>
                        <p>
                            To receive the 10% Off Food and Beverage Pilot Flying J Military discount, you must be authenticated in the 
                            myRewards Plus app through ID.me™ and must present myRewards card, barcode in app, or phone number associated 
                            with myRewards account at time of purchase. present one of the following 1) your myRewards card, 2) barcode 
                            in the myRewards Plus app, 3) pay with the app, or 4) phone number associated with your myRewards account at 
                            the time of purchase. Valid only at participating U.S. Pilot or Flying J branded, owned and/or operated locations 
                            and One9 locations. Not guaranteed at dealer/licensee locations or locations operated under a different brand 
                            name.Offer valid at participating Pilot, Flying J, and One9 Fuel Network owned and operated locations. Not 
                            guaranteed at dealer/licensee locations or locations under a different brand name. Discount valid in United 
                            States and Canadian locations to United States and Canadian military guests who have verified using ID.me. 
                            Military discount valid only on food and beverage purchases only, including cPilot Coffee, fountain, hot and 
                            cold food items, purchases at participating owned and operated QSR restaurants, non-alcoholic cooler beverages, 
                            candy, and snacks. Void where prohibited or otherwise restricted. Purchase required. Invalid on prior purchases. 
                            Cannot be sold, bartered, or combined with other offers. No cash value, rain checks, or substitutions allowed. 
                            Offer may be modified or terminated at any time. Data rates may apply.
                        </p>
                            
                        <h3 className='section__sub-title'>
                            Testimonials
                        </h3>
                        <p>
                            Any quotes, personal accounts, or testimonials featured in PTC communication channels, are opinions applicable 
                            to specific individuals and may not be representative of other myRewards Plus app user experiences.
                        </p>

                        <h3 className='section__sub-title'>
                            Gift Cards
                        </h3>
                        <h6><u>About Your Card</u></h6> <br />
                        <p>
                            Gift Cards and eGift Cards (collectively “Gift Card(s)”) may be used up to the balance available on the Gift 
                            Card(s) for purchases of eligible goods or services at participating Pilot, Flying J, and One9 Fuel Network 
                            owned and operated locations. Not guaranteed at dealer/licensee locations or locations operated under a 
                            different brand name. The terms and conditions associated with this card are subject to change without notice. 
                            Gift Cards are issued by Cardco II, Inc.
                        </p>
                        <h6><u>Purchase</u></h6> <br />
                        <p>
                            Gift Cards can be purchased and activated at participating PTC locations and online at <a href="https://www.pilotflyingj.com/giftcards" 
                            target="_blank" rel="noreferrer noopener">https://www.pilotflyingj.com/giftcards</a>. 
                            eGift cards are delivered via email. If your eGift Card does not arrive after a period of 48 hours, please verify your email 
                            address or check your email provider’s spam filter. If you need further assistance, please call &nbsp;
                            <a
                                href="tel:888-230-1557"
                                onClick={() =>
                                    this.buttonClicked('Phone Number Clicked')
                                }
                            >
                                888-230-1557
                            </a> or contact 
                            Guest Services at 1-877-866-7378.
                        </p>
                        <h6><u>Redeem</u></h6> <br />
                        <p>
                            Gift Cards can be used for the purchase of goods and services at PTC locations. Except where required by 
                            applicable law, the Gift Cards cannot be redeemed for cash and cannot be used for purchases of lottery, 
                            money orders, payment of PTC credit, purchase of another gift card, or as prohibited by local laws and 
                            ordinances. To redeem your Gift Card’s value, present your physical Gift Card or eGift Card code at 
                            the time of checkout.
                        </p>
                        <h6><u>Reload</u></h6> <br />
                        <p>
                            Gift Cards can be reloaded at PTC locations or online at  <a href="https://www.pilotflyingj.com/giftcards" 
                            target="_blank" rel="noreferrer noopener">https://www.pilotflyingj.com/giftcards</a>.  Select older cards are not eligible for reload.
                        </p>

                        <h6><u>Check Your Balance</u></h6> <br />
                        <p>
                            To view your Gift Card balance, visit <a href="https://www.pilotflyingj.com/giftcards" 
                            target="_blank" rel="noreferrer noopener">https://www.pilotflyingj.com/giftcards</a> or call &nbsp; 
                            <a
                                href="tel:888-230-1557"
                                onClick={() =>
                                    this.buttonClicked('Phone Number Clicked')
                                }
                            >
                                888-230-1557
                            </a>.
                        </p>

                        <h6><u>Expiration</u></h6> <br />
                        <p>
                            Gift Card balances do not expire and there are no service or inactivity fees.
                        </p>

                        <h6><u>Lost, Stolen, or Damaged Cards</u></h6> <br />
                        <p>
                            PTC is not responsible if your Gift Card is lost, stolen, or used without authorization. 
                            The value of any lost, stolen, or damaged card will not be replaced, except in limited cases, 
                            with proof of purchase. If you believe your Gift Card has been lost or stolen, please call&nbsp;
                            <a
                                href="tel:888-230-1557"
                                onClick={() =>
                                    this.buttonClicked('Phone Number Clicked')
                                }
                            >
                                888-230-1557
                            </a> immediately.
                        </p>

                        <h6><u>Returns</u></h6> <br />
                        <p>
                            Gift Cards themselves cannot be returned or refunded. Items purchased with a Gift Card are eligible 
                            for a refund with valid proof of purchase.
                        </p>

                        <h6><u>Limitations</u></h6> <br />
                        <p>
                            Gift Cards cannot be used for: lottery, money orders, payment of PTC credit, purchase of another gift card, 
                            or as prohibited by local laws and ordinances. The maximum value that can be associated with a single Gift 
                            Card is $1,000.00 per 24-hour period, to the maximum of $2,000.00.
                        </p>

                        <h6><u>Pay</u></h6> <br />
                        <p>
                            The Pay feature is a contactless payment method located in the myRewards Plus app. Pay can be loaded with funds 
                            via PTC Gift Cards, PTC eGift Cards, or stored credit and debit cards. Stored payment methods are secure, and 
                            your personal card information will never be shared or sold by PTC. Please refer to our <a href="https://pilotflyingj.com/privacy-notice" 
                            target="_blank" rel="noreferrer noopener">Privacy Notice</a> for further 
                            information on how PTC handles personal information. Funds stored in Pay are subject to 
                            PTC’s Gift Card Terms and Conditions. <br /><br />
                        
                        <ul>
                            <li><u>Loading Pay:</u>&nbsp;Pay can be loaded with funds via the myRewards Plus app using PTC Gift Cards or stored 
                            credit/debit cards. The maximum value that can be loaded is $1,000.00 per 24-hour period, to the maximum of 
                            $2,000.00. The minimum amount that can be loaded in one transaction is $10.00. PTC may change any of these 
                            amounts at any time without notice.
                            </li> <br />

                            <li>
                                <u>Loading Credit and Debit Cards:</u>&nbsp;If you use a credit or debit card to reload your Pay, a temporary 
                                hold for the selected amount may appear in your bank account during the card verification process. Please 
                                refer to the rules and regulations of your financial institutions for further information about transaction 
                                holds. Your card will only be charged upon approval and fulfillment of the transaction.
                            </li> <br/>

                            <li>
                                <u>Managing Stored Payment Methods:</u>&nbsp;A maximum of (5) credit/debit cards can be stored in Pay. Stored 
                                payment methods can be added/removed at any time. Expired cards will not be removed from your wallet, but they 
                                will be disabled for use. Stored payment methods are secure, and your personal card information will never be 
                                shared or sold by PTC. Please refer to our <a href="https://pilotflyingj.com/privacy-notice" 
                                target="_blank" rel="noreferrer noopener">Privacy Notice</a> for further information regarding how PTC handles 
                                your personal information.
                            </li> <br />

                            <li>
                                <u>Loading PTC Gift Cards:</u>&nbsp; Loading a Gift Card to Pay is a one-time transaction where the entire value 
                                of the Gift Card is transferred to your Pay balance. Gift Cards are not stored in your payment options and they 
                                carry no value once redeemed. Gift Cards may, however, be reloaded with value, per PTC’s Gift Card Terms and 
                                Conditions, and then used again to reload your Pay account.
                            </li> <br />

                            <li>
                                <u>Purchasing:</u>&nbsp;Pay can be used for the in-store purchase of goods and services at PTC locations. 
                                Pay cannot be used for purchases of lottery, money orders, payment of PTC credit, purchase of other gift 
                                cards, or as prohibited by local laws and ordinances. Pay is currently unavailable for use at the fuel pumps. 
                                However, fuel can be purchased in-stores using Pay.
                            </li> <br/>
                            
                            <li>
                                <u>Rewards:</u>&nbsp;Purchases initiated through Pay are eligible to receive myRewards points, which can be 
                                redeemed for select goods and services at PTC locations. Please refer to our <a href="https://pilotflyingj.com/terms-and-conditions" 
                                target="_blank" rel="noreferrer noopener">myRewards Program Overview</a> for further information.
                            </li> <br/>
                        </ul>
                        </p>

                        <h3 className='section__sub-title'>
                            Shower Credits
                        </h3>
                        <p>
                            Each person who presents an active Professional Driver or RV myRewards Card will receive the following shower credits:
                            <ul>
                                <br />
                                <li>50 gallons or more in a single purchase of gas or diesel fuel = 1 free shower credit good for 10 days</li>
                                <li>14 to 49 gallons in a single purchase of gas or diesel fuel = 0.5 free shower credit good for 10 days</li>
                            </ul>
                        </p>
                        <p>
                            Those active Members in the myRewards program who fuel over 1,000 gallons in a calendar month will be included 
                            in Shower Power. Members with Shower Power will receive two showers per day for the remainder of the month and 
                            the entire next calendar month.
                        </p>

                        <h3 className='section__sub-title'>
                            Expiration of My Rewards Points/ Lost or Stolen Cards
                        </h3>
                        <p>
                            Except for Purchased Points, if a Member’s card remains inactive for a period of four (4) calendar months, 
                            as permitted by law, the Member will lose 250 myRewards Points per month starting on the first (1st) day of 
                            the fifth (5th) calendar month. As permitted by law, the Member will continue to lose 250 My Rewards Points 
                            on the first (1st) of each subsequent month for each month of inactivity.  Once the card has been inactive 
                            for (12) calendar months, all points will be deducted from the account, as permitted by law. These myRewards 
                            Points are charged as a maintenance fee for inactive use. <u>If a Member’s Card is lost or stolen, please immediately 
                            contact Guest Services at 1-877-866- 7378</u>.  Please note that for security reasons, PTC customer service is not 
                            permitted to provide account numbers over the phone.  Lost or stolen cards will be de-activated and all remaining 
                            myRewards Points on the lost or stolen card at the time the report is made to PTC customer service will be credited 
                            to a new Card. When transferring to a new Card as a result of a lost or stolen Card, you will be required to verify 
                            certain account information. An account balance transfer to a new Card will not be issued unless proof of identity is 
                            confirmed. PTC reserves the right to charge a fee for replacement Cards.
                        </p>

                        <h3 className='section__sub-title'>
                            Mobile Fueling
                        </h3>
                        <p>
                            Mobile Fueling is a myRewards Plus app feature that allows drivers to start a fuel pump directly from the app. 
                            Must have a myRewards account, use the myRewards Plus app, and add at least one payment card to the digital 
                            wallet in order to use Mobile Fueling. See https://pilotflyingj.com/mobile-fueling for instructions on how to 
                            use Mobile Fueling. In Canada, Mobile Fueling is only available to professional drivers using a professional 
                            driver myRewards account. Mobile Fueling may be discontinued or modified at any time with or without notice.
                        </p>

                        <h3 className='section__sub-title'>
                            Pilot Flying J Good Sam RV PLUS Card Program
                        </h3>
                        <p>
                            The Pilot Flying J Good Sam RV Plus Card Program is a loyalty program built in partnership with Good Sam, 
                            the loyalty program at Camping World. RV Customers must register with Good Sam to receive benefits in the 
                            Pilot Flying J Good Sam RV PLUS Program. All terms and conditions pertaining to the myRewards Program also 
                            pertain to the Pilot Flying J Good Sam RV PLUS program. The Pilot Flying J Good Sam RV PLUS Program is not 
                            eligible for all benefits and PTC reserves the right to change or discontinue parts of the program without 
                            notice. The key benefits to the Pilot Flying J Good Sam RV PLUS Customer are discounts on petroleum products 
                            at the pump and in-store deal.
                        </p>

                        <h3 className='section__sub-title'>
                            Miscellaneous
                        </h3>
                        <p>
                            PTC provides the myRewards Program, its materials, and other services and products "As Is, Where Is and 
                            With All Faults." PTC, to the fullest extent permitted by applicable law, disclaims any and all representations 
                            or warranties of any kind, express or implied, including but not limited to warranties of merchantability and 
                            fitness for a particular purpose.  PTC disclaims and Member agrees to waive and hold PTC harmless from all 
                            responsibility for any loss, injury, claim, liability, or damage of any kind resulting from, arising out of, 
                            or in any way related to (a) any errors in or omissions from this myRewards Program, including but not limited 
                            to technical inaccuracies and typographical errors, (b) the unavailability of this myRewards Program or any portion 
                            thereof, and (c) Member’s use of the myRewards Program. Under no circumstances will PTC be liable for any direct, 
                            indirect, incidental, special, punitive or consequential damages arising out of the use and membership of the myRewards 
                            Program or any such other products or services, even if PTC has been advised of the possibility of such damages. 
                            For purposes of this section "PTC" means Pilot Travel Centers LLC, its affiliates, and any officer, director, 
                            employee, subcontractor, agent, successor, parent company or assign of any of them.
                        </p>
                        <p>
                            The myRewards Program and Member’s participation herein is subject to all applicable laws and regulations. 
                            Rewards and advantages may be subject to income or other taxes. Such taxes and all disclosures related thereto 
                            are Member’s sole responsibility. PTC shall not be responsible for correspondence lost or delayed or unpaid taxes, 
                            fees, fines or penalties. PTC is not responsible for, and reserves the right to correct any pricing or typographical 
                            errors, errors of description, or errors regarding offers or myRewards Points.
                        </p>
                        <p>
                            If PTC or its affiliates improperly deny a member myRewards Points or some other benefit, your exclusive remedy 
                            shall be the issuance of the improperly denied credit, award or benefit if available, or such other comparable 
                            alternative, determined in the sole discretion of PTC.
                        </p>
                        <p>
                            PTC reserves the right to correct any myRewards Points value or other benefit granted in error. PTC is not 
                            responsible for late, lost, incomplete, or misdirected entry information or communications; computer system, 
                            phone line, electronic equipment, computer hardware, software or myRewards malfunctions, or other errors; 
                            failures or delays in computer transmissions or network connections; or for any other technical problems.  
                            PTC is not responsible for incorrect or inaccurate entry information, whether caused by Members or by any 
                            of the equipment or associated with or utilized in the reward, or by any technical or human error which may 
                            occur in the processing of myRewards Points and account information.
                        </p>
                        <p>
                            These Terms of Use incorporate by reference any notices contained herein and constitute the entire agreement 
                            with respect to your use, access and benefit of this myRewards Program.
                        </p>
                        <p>
                            Any communication or other information sent to PTC via electronic mail or otherwise in connection with Member’s 
                            use of this myRewards Program, including but not limited to suggestions, ideas and comments, will be treated as 
                            non-confidential and all such information may be used by PTC for any purpose without compensation. Disclosure 
                            shall constitute an assignment of all right, title and interest in such information to PTC.  Provided however, 
                            social security numbers, driver’s license, CLM number or date of birth will be treated as confidential and not 
                            sold to any third party. PTC does reserve the right to use a customer’s information for its own or its vendors’ 
                            marketing purposes.
                        </p>
                        <p>
                            Please note, the myRewards Program may contain references or cross references to PTC products and services that 
                            are not available in every state, country or even to every person. In such an event, the myRewards Program will 
                            be void where prohibited.
                        </p>

                        <h3 className='section__sub-title'>
                            <u>TERMS SPECIFIC TO THE PRIME PARKING PROGRAM</u>
                        </h3>
                        <p>Prime Parking may not be available at all PTC stores.A list of participating stores can be found at www.pilotflyingj.com/locations.</p>
                        <p>
                            In addition to the Terms of Use, the following additional terms apply to your use of Prime Parking.  Should any of these terms 
                            conflict, the specific terms below shall trump the general Terms of Use.
                            <ol>
                                <br />
                                <li>
                                    Guest must be a PTC myRewards member to create or edit Prime Parking reservations.
                                </li>
                                <li>
                                    Parking reservations start at 4 p.m. on the day of reservation until 3 p.m. the next 
                                    calendar day. Window tag must be visible in the driver-side window at all times.
                                </li>
                                <li>
                                    A member of PTC’s store management must be notified if a guest plans on departing 
                                    and returning before the end of the reserved time, or the space will be considered 
                                    available for resale.
                                </li>
                                <li>
                                    To edit a reservation, please contact Guest Services at 1-877-866-7378. Reservations 
                                    can be canceled at any time, but will only be eligible for refunds if canceled prior 
                                    to 12 p.m. on the check-in date (times are local to the reservation site).
                                </li>
                                <li>
                                    No dropped trailers are allowed. Vehicles occupying more than one space must reserve the 
                                    quantity of spaces they occupy. PTC has the right to cancel any reservation that does not 
                                    meet this requirement.
                                </li>
                                <li>
                                    Reservations merely permit the driver to use spaces for parking during reserved paid time. 
                                    Vehicles left without proper reservations or remaining in parking spaces after expiration of 
                                    time may be impounded or towed away with or without notice, at the guest's sole cost and expense. 
                                    You agree to abide by all applicable laws and regulations and PTC’s policies and procedures while 
                                    parked on PTC's owned or leased property.
                                </li>
                                <li>
                                    YOU AGREE TO PARK AT YOUR OWN RISK. PTC IS NOT LIABLE FOR ANY FIRE, LOST, DAMAGED OR STOLEN VEHICLES, 
                                    TRAILERS, CARGO, CONTENT OR PERSONAL PROPERTY. BY USING THIS SERVICE, YOU AGREE TO WAIVE AND HOLD PTC 
                                    HARMLESS FROM ANY AND ALL RIGHTS OF CLAIMS, DAMAGES, EXPENSES OR LIABILITY AGAINST PTC, THIS INCLUDES 
                                    ANY CLAIMS THAT CAN BE BROUGHT BY YOU OR THOSE ON YOUR BEHALF. YOUR RELEASE SHALL BE ENFORCED WITHOUT 
                                    REGARD TO THE ACTUAL OR ALLEGED NEGLIGENCE OR OTHER FAULT OF PTC, IT BEING YOUR EXPRESS INTENTION TO 
                                    RELEASE PTC FOR THE CONSEQUENCE OF YOUR OWN ACTUAL OR ALLEGED NEGLIGENCE FAULT OF PTC OR OTHER FAULT 
                                    RELATED TO THE OPERATION OF THIS PARKING LOT. YOU AGREE PTC'S MAXIMUM LIABILITY WILL BE LIMITED TO THE 
                                    PRICE OF THE RESERVATION.
                                </li>
                            </ol>
                        </p>

                        <h3 className='section__sub-title'>
                            <u>WI-FI TERMS OF USE AGREEMENT</u>
                        </h3>
                        <p>
                            Wi-Fi may not be available at all PTC stores. A list of participating stores can be found at <a href="https://pilotflyingj.com/terms-and-conditions" 
                            target="_blank" rel="noreferrer noopener">www.pilotflyingj.com/locations</a>.
                        </p>
                        <p>
                            In addition to the Terms of Use, the following additional terms (“Wi-Fi Terms”) apply to and govern the access 
                            and use and your access to and use of Wi-Fi Service (this or the "Service") while at PTC Locations throughout 
                            North America (“Travel Center”).  Only current guests of PTC may use the Service (“Wi-Fi Agreement”). Any 
                            subscription purchased at a dealer or licensee location (inside or outside on the parking lot) will not be 
                            valid at any PTC location.  All Wi-Fi purchased at the point of sale inside the store, not through the  captive 
                            portal, will be non-refundable.  To the extent there is any conflict with these specific terms related to Wi-Fi 
                            use and the terms and conditions set forth herein, the more specific shall control.
                        </p>
                        <p>
                            BY ACCESSING OR USING THIS SERVICE, YOU REPRESENT THAT YOU ARE CURRENTLY A GUEST OF THIS TRAVEL CENTER AND 
                            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS AND CONDITIONS OF THE WI-FI TERMS, AND AGREE 
                            TO BE LEGALLY BOUND BY THEM. IF YOU ARE NOT A CURRENT GUEST OF THIS TRAVEL CENTER AND/OR YOU DO NOT AGREE TO 
                            OR UNDERSTAND THE WI-FI, THEN YOU MAY NOT USE OR ACCESS THIS SERVICE.
                        </p>
                        <p>
                            By using this Service you warrant and promise, that you have legal capacity to enter into this Wi-Fi Agreement 
                            and to consent to the Wi-Fi Terms (i.e., that you are of sufficient age and mental capacity, or are otherwise 
                            entitled to be legally bound in contract) and accept this Wi-Fi Agreement and Wi-Fi Terms on your behalf.
                        </p>
                        <p>
                            In consideration of your use and access to this Service and other good and valuable consideration (the receipt 
                            and sufficiency of which are hereby acknowledged), you agree that this Wi-Fi Agreement is for the joint and 
                            several benefit of PTC and all of its affiliates, lessees and any of their respective service providers 
                            (hereinafter collectively "PTC") and PTC shall have the right to enforce the terms of this Wi-Fi Agreement. 
                            "Third Party Provider" means the third party provider of PTC providing the Service.
                        </p>
                        
                        <p> 
                                <h6>1. &nbsp;CONSENT TO MONITORING.</h6> <br />
                                <p>
                                    PTC AND THE THIRD PARTY PROVIDER RESERVE THE RIGHT TO, AND YOU ACKNOWLEDGE AND CONSENT, THAT PTC 
                                    AND/OR THE THIRD PARTY PROVIDER MAY (BUT ARE NOT REQUIRED TO) MONITOR YOUR COMMUNICATIONS AND 
                                    ACTIVITIES VIA THIS SERVICE (INCLUDING THEIR CONTENT) DURING TRANSMISSION, AND IN CONNECTION 
                                    WITH THE USE OF THIS SERVICE, INCLUDING WITHOUT LIMITATION INFORMATION ABOUT DEVICES THROUGH WHICH 
                                    YOU ACCESS THE SERVICE. PTC AND THE THIRD PARTY PROVIDER MAY USE AND DISCLOSE ANY SUCH INFORMATION 
                                    FOR PURPOSES OF ADMINISTERING THE SERVICE AND PROVIDE SERVICE FUNCTIONALITY, ANALYZING USAGE, ENSURING 
                                    YOUR COMPLIANCE WITH THIS WI-FI AGREEMENT, APPLICABLE LAW, IN COOPERATION WITH LEGAL AUTHORITIES, AND AS 
                                    OTHERWISE REQUIRED TO PROTECT PTC’S AND THE THIRD PARTY PROVIDER’S RIGHTS, PROPERTY AND INTERESTS.
                                </p>
                                <h6>2. &nbsp;USE OF THIS SERVICE.</h6><br />
                                <ul>
                                    <li>You agree that you are fully responsible for your activities while using this Service (including 
                                        for any content, information and other materials you access or transmit via this Service), and 
                                        that you shall bear all risks regarding use of this Service. You agree not to use this Service to 
                                        engage in any Prohibited Conduct. Broadly stated, "Prohibited Conduct" is any conduct that is unlawful, 
                                        infringing (such as downloading copyright protected material, video, audio or otherwise without the 
                                        owner’s permission), tortious (conduct that is intentionally harmful to an individual or entity or 
                                        conduct that negligently or knowingly puts an individual or entity at risk), or any other online 
                                        conduct that a reasonable individual would or should know would violate another party's intellectual 
                                        property rights, privacy rights or other rights; or conduct that otherwise interferes with the operation 
                                        of, use of, or enjoyment of, any service, system or other property.
                                    </li>
                                    <li>
                                        By way of illustration and not limitation, Prohibited Conduct includes using this Service to: 
                                        (i) intercept, divert or otherwise interfere with any communication, (ii) violate the security 
                                        or integrity of, or gain unauthorized access to, this Service or any other service, system or 
                                        communication; (ii) impose an unreasonable or disproportionately large load on any systems or 
                                        infrastructure; (iii) send "spam", chain letters, or other unsolicited communications to any 
                                        party; (iv) create a "mail drop" for such communications, or engage or permit e-mail relay 
                                        services (e.g., "open mail relay"); (v) "spoof" or otherwise impersonate any other party, falsely 
                                        stating or otherwise misrepresenting your identity or affiliation in any way, or forge, delete or 
                                        alter any part of TCP/IP packet header or sender identification information in any communication; 
                                        (vi) commit fraud; (vii) harass, or threaten any party, advocate or otherwise encourage violence 
                                        against any government, organization, group, individual or property, or provide instruction, 
                                        information, or assistance in causing or carrying out such violence; (viii) disseminate viruses, 
                                        Trojan horses, or other code or programming intended to damage, interfere with, intercept or expropriate 
                                        any system, data or personal information; (ix) send or receive any material that could be considered harmful, 
                                        obscene, pornographic, indecent, lewd, violent, abusive, profane, insulting, threatening, tortious, harassing, 
                                        hateful or otherwise objectionable; (x) send or receive any material that harasses, victimizes, degrades, or 
                                        intimidates an individual or group of individuals on the basis of religion, race, ethnicity, sexual orientation, 
                                        gender, age, or disability; (xi) send or receive material containing defamatory, false, or libelous material; (xii) 
                                        send,  receive, download or print any material, audio, video or otherwise, that infringes or violates any intellectual 
                                        property rights, or other right, of any entity or person, including, without limitation, copyrights, patents 
                                        and/or trademarks, laws governing trade secrets, rights to privacy, or publicity; (xiii) send or receive any 
                                        material that you do not have a right to make available under law, contractually (for example, it was not paid 
                                        for) or under fiduciary relationships; (xiv) engage in conduct that would expose PTC or its service providers 
                                        to civil or criminal liability; or (xv) assist others in engaging in Prohibited Conduct. This list of Prohibited 
                                        Conduct is meant to serve as examples of prohibited conduct and is not intended to be and should not be construed 
                                        to be exhaustive. PTC requires and expects that you will be a good Internet citizen and use good judgment when using this Service.
                                    </li> <br />
                                </ul>
                                <h6>3. &nbsp;INDEMNIFICATION.</h6><br />
                                <p>
                                    You agree to indemnify, defend, and hold harmless PTC, the Third Party Provider and their respective affiliates, 
                                    officers, directors, shareholders and employees, from and against any and all claims, actions, demands, 
                                    liabilities, costs and expenses, including, without limitation, reasonable attorneys' fees, arising out of or 
                                    resulting from your breach of any provision of this Wi-Fi Agreement or any warranty or promise you provide 
                                    herein, or otherwise arising in any way out of your use of the Service, including, but not limited to, using 
                                    the Service for Prohibited Conduct. PTC reserves the right to take exclusive control and defense of any such 
                                    claim otherwise subject to indemnification by you, in which event you will cooperate fully with PTC and/or the 
                                    Third Party Provider in asserting any available defenses.
                                </p>
                                <h6>4. &nbsp;CONSEQUENCES OF UNAUTHORIZED USE.</h6> <br />
                                <p>
                                    Without limiting any other available right or remedy, PTC and the Third Party Provider reserve the 
                                    right to, and you agree that PTC and/or the Third Party Provider shall have the right to: (i) take 
                                    such actions as it deems, in its sole discretion, to be appropriate to protect against violations 
                                    of this Wi-Fi Agreement or abuse of the Service and to otherwise protect its interests (e.g., 
                                    removing, destroying or otherwise making unavailable offending material, temporary or permanent 
                                    filtering, blocking access, and suspending or terminating service), and (ii) investigate and 
                                    involve and cooperate with appropriate authorities regarding any actual or suspected illegal or 
                                    unauthorized activities involving this Service. You agree that you will be liable to PTC and the 
                                    Third Party Provider for any damages incurred or amounts that are required to be paid by PTC and/or 
                                    the Third Party Provider that arise out of, or are related to, your violation of this Wi-Fi Agreement, 
                                    including, without limitation, damages paid to third parties, cost of repairs or replacements, and 
                                    reasonable attorneys' fees and costs of enforcement.
                                </p>
                                <h6>5.&nbsp;YOU ARE RESPONSIBLE FOR YOUR SECURITY AND PRIVACY.</h6> <br />
                                <p>
                                    Although privacy and security are important to PTC and the Third Party Provider, you 
                                    understand and agree that you shall have no expectation of privacy or security in your 
                                    use of this Service. There are privacy and security risks associated with wireless 
                                    communications and the Internet in general and you acknowledge that PTC and the Third 
                                    Party Provider make no assurances that your communications, or activities while using 
                                    the Services, will be (or will remain) private or secure, and you further agree that PTC 
                                    and the Third Party Provider assume no responsibility in that regard. You agree that you, 
                                    and not PTC or the Third Party Provider, are solely responsible for your own privacy and 
                                    security in using this Service, and for implementing any protections you deem to be appropriate 
                                    to protect and secure your privacy, and your activities, hardware, software and systems.
                                </p>
                                <h6>6.&nbsp;DISCLAIMER OF WARRANTY.</h6> <br />
                                <ul>
                                    <li>
                                        YOU ACKNOWLEDGE AND AGREE THAT THIS SERVICE IS PROVIDED SOLELY AS A CONVENIENCE TO 
                                        GUESTS OF THIS TRAVEL CENTER, "AS IS", "WITH ALL FAULTS", AND "AS AVAILABLE". YOU 
                                        FURTHER ACKNOWLEDGE AND AGREE THAT PTC AND THE THIRD PARTY PROVIDER DISCLAIM ALL 
                                        WARRANTIES AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR BY LAW, 
                                        STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF: (i) MERCHANTABILITY, 
                                        (ii) FITNESS FOR A PARTICULAR PURPOSE, (iii) NON-INFRINGEMENT, (iv) WORKMANLIKE EFFORT, (v) 
                                        QUALITY, (vi) ACCURACY, (vii) TIMELINESS, (viii) COMPLETENESS, (ix) TITLE, (x) QUIET ENJOYMENT, 
                                        (xi) NO ENCUMBRANCES, (xii) NO LIENS, (xiii) SYSTEM INTEGRATION OR (xiv) THAT ACCESS TO OR USE 
                                        OF THIS SERVICE OR ANY PART THEREOF WILL BE UNINTERRUPTED, ERROR OR DEFECT FREE, FREE OF VIRUSES 
                                        OR OTHER HARMFUL CODE, OR SECURE, OR THAT PROBLEMS WILL BE CORRECTED, EVEN IF PTC IS ON NOTICE OF 
                                        SUCH PROBLEMS. YOU FURTHER AGREE AND UNDERSTAND THAT THERE ARE NO WARRANTIES THAT EXTEND BEYOND 
                                        THE FACE OF THIS WI-FI AGREEMENT; NO ADVICE OR INFORMATION GIVEN BY PTC, THE THIRD PARTY PROVIDER 
                                        OR THEIR RESPECTIVE EMPLOYEES OR AGENTS, SHALL CREATE ANY WARRANTY, GUARANTY, PROMISE, OR OTHERWISE. 
                                        IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES, YOU 
                                        AGREE THAT THE ABOVE EXCLUSIONS WILL APPLY TO YOU TO THE EXTENT ALLOWED BY APPLICABLE LAW.
                                    </li>
                                    <li>
                                        The Internet contains a variety of materials and information that may be offensive to you. 
                                        You agree that you assume full responsibility and risk of use of the Services and the Internet, 
                                        and that you are solely responsible for evaluating the suitability, appropriateness or legality 
                                        of any informational content or other materials you may encounter online. PTC or the Third Party 
                                        Provider may, but need not, provide screening of certain materials. Software products that enable 
                                        screening of particular materials and information are commercially available and widely advertised 
                                        in public media, including on the Internet.
                                    </li> <br />
                                </ul>
                                <h6>7.&nbsp;LIMITATION OF PTC'S AND THE THIRD PARTY PROVIDER’S LIABILITY.</h6> <br />
                                <ul>
                                    <li>
                                        YOU ACKNOWLEDGE AND AGREE THAT THIS SERVICE IS PROVIDED AS A GUEST PRIVILEGE, SOLELY FOR CONVENIENCE, 
                                        AND USE OF THE SERVICE DOES NOT IMPOSE LIABILITY OF ANY KIND (OR IN ANY AMOUNT) ON PTC OR THE THIRD 
                                        PARTY PROVIDER, INCLUDING WITHOUT LIMITATION, LIABILITY FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, 
                                        INCIDENTAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF BUSINESS, 
                                        BUSINESS INTERRUPTION, LOSS OF DATA, OR OTHER SIMILAR DAMAGES) ARISING OUT OF OR RELATED TO THIS WI-FI 
                                        AGREEMENT OR THIS SERVICE EVEN IF PTC OR ITS THIRD PARTY PROVIDER IS ADVISED OF THE POSSIBILITY OF ANY 
                                        SUCH DAMAGES.
                                    </li>
                                    <li>
                                        YOU FURTHER AGREE THAT IF FOR ANY REASON ANY OF THE FOREGOING LIMITATIONS OF LIABILITY IS HELD TO 
                                        FAIL OF ITS ESSENTIAL PURPOSE OR IS OTHERWISE DEEMED TO BE UNENFORCEABLE, THEN THE MAXIMUM AGGREGATE, 
                                        CUMULATIVE LIABILITY OF PTC AND ITS THIRD PARTY PROVIDER, ARISING OUT OF, OR RELATED TO, THIS WI-FI 
                                        AGREEMENT AND THE USE OF THE SERVICE SHALL NOT EXCEED THE LESSER OF (I) YOUR DIRECT DAMAGES (IF ANY), 
                                        OR (II) FIFTY DOLLARS ($50.00).
                                    </li>
                                    <li>
                                        YOU AGREE THAT THE LIMITATIONS IN THIS SECTION SHALL BE DEEMED TO APPLY TO ALL CAUSES OF ACTION 
                                        AND ALL LEGAL THEORIES, WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM: (i) BREACH OF CONTRACT, 
                                        BREACH OF WARRANTY, GROSS NEGLIGENCE, NEGLIGENCE OR OTHER TORTIOUS CONDUCT, OR (ii) ANY OTHER CAUSE 
                                        OF ACTION, HOWEVER STATED.
                                    </li><br />
                                </ul>
                                <h6>8.&nbsp;TERMINATION OF SERVICE; UPDATES TO THIS WI-FI AGREEMENT.</h6> <br />
                                <p>
                                    You agree that PTC or the Third Party Provider may, at any time and for whatever reason change, 
                                    terminate, limit or suspend the Service (in whole or in part) or your access to the Service. 
                                    Upon any termination, your rights to use the Service will immediately cease. PTC also reserves 
                                    the right to update or revise this Wi-Fi Agreement at any time without prior notice, and you agree 
                                    that PTC may do so, and agree that you will read, review and acknowledge this Wi-Fi Agreement prior 
                                    to each and every use of the Service. Notwithstanding the foregoing sentence, your continued use of 
                                    the Service following an update to this Wi-Fi Agreement signifies your acceptance of its revised terms.
                                </p>
                                <h6>9.&nbsp;MISCELLANEOUS.</h6> <br />
                                <p>
                                    You agree that: this Wi-Fi Agreement and your use of the Service shall be governed by (i) the laws 
                                    of the State of Tennessee, without regard to conflicts of laws principles (if you obtain this 
                                    Service within the United States), or (ii) the laws of Ontario, Canada, without regard to 
                                    conflict of laws principles (if you obtain this Service within Ontario, Canada), and PTC and/or 
                                    its Third Party Provider, shall have at all times the right to seek any injunctive or equitable 
                                    relief available to it under applicable laws. You agree that the exclusive jurisdiction for all 
                                    controversies or claims shall be (i) the federal and state courts in the State of Tennessee in 
                                    Knox County (if you obtain this Service within the United States) or (ii) the courts in Ontario, 
                                    Canada (if you obtain this Service within Ontario, Canada), and you agree that such courts will 
                                    have personal jurisdiction over you in such matters through your use of the Service. You agree 
                                    that this Wi-Fi Agreement is solely between you and PTC. You agree that any action you bring that 
                                    arises from, or relates to, the Service must be initiated within one year from the relevant date of 
                                    your access to the Service, and that no cause of action may be brought after that time. You agree 
                                    that if any provision(s) of this Wi-Fi Agreement is determined to be invalid or unenforceable under 
                                    applicable law, then such provision(s) will be ineffective to the extent of such determination, and 
                                    such invalidation of such part or portion of this Wi-Fi Agreement shall not invalidate the remaining 
                                    portions of this Wi-Fi Agreement and/or the spirit of this Wi-Fi Agreement and the remainder of this 
                                    Wi-Fi Agreement will continue and shall remain in full force and legal effect. You agree that this 
                                    Wi-Fi Agreement constitutes the entire agreement between you and PTC regarding its subject matter, 
                                    and it supersedes any prior or contemporaneous agreements between you and PTC regarding its subject 
                                    matter. You expressly consent to the rendering of this Wi-Fi Agreement in English. 
                                </p>
                        </p>
                    </div>
                </PrivacyStyled>
            </Layout>
        );
    }
}

export default withSize()(Privacy);
