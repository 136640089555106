import styled from 'styled-components';

const PrivacyStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .privacy__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 92%;
        margin-top: 85px;

        @media screen and (min-width: 1024px) {
            width: 92%;
            max-width: 800px;
        }

        ul > li {
            margin-left: 40px;
        }

        ol > li {
            margin-left: 40px;
        }
    }

    .section__title,
    .section__sub-title,
    p {
        margin-bottom: 3.25rem;
        text-align: left;
    }

    p {
        font-size: 0.875rem;

        @media screen and (min-width: 1024px) {
            font-size: 1.125rem;
        }
    }
    h6 {
        font-weight: 200;
        font-size: 1rem;
    }

    a {
        color: ${props => props.theme.colors.primaryYellow};
    }
`;

export default PrivacyStyled;
